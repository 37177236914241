<template>
  <v-app class="section-box ">
    <v-banner class="lavBanner" single-line  color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/shijieCaseBanner1.png"
        width="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" >
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-56 text-shadow-item font-weight-bold font-size-banner-title">
              逐渐成长为专家
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-7 font-size-20 text-shadow-item" style="line-height:32px">
              选择你所感兴趣的内容进行学习吧
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

<div class="wrap-out">
    <v-container class="main-box max-width-1200">
      <div class="main-item width-per-100">
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs-two mb-3  width-per-100" >
          <v-tab v-for="(item,index) in data" :key="index">
            <v-card-title class="font-size-16" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.title }}</v-card-title>
          </v-tab>
        </v-tabs>
        <div class="new-two">
        <v-tabs-items v-model="tab" >
            <v-tab-item v-for="(item,index) in data" :key="index"  style="margin-bottom:30px">
                <v-row v-show="tab === index" >
                    <v-col v-for="(info,infoIndex) in item.tabItem" :key="infoIndex" cols="3" class="pa-4">
                        <v-card
                            class="mx-auto item-card pa-0"
                            max-width="100%"
                            max-height="100%"
                            min-height="300"
                            @click="goCaseDetail(info.id)"
                        >
                            <v-img
                                max-width="100%"
                                contain
                                class="tran-sec"
                                :src="info.coverImg"
                            >
                            </v-img>
                            <div class="text-box px-4">
                                <v-card-text class="font-size-14 sizeStyle pa-0 mt-4 mb-2 color-333 font-weight-bold title line-height-20" v-text="info.itemTitle"></v-card-text>
                                <v-card-text class="font-size-12 sizeStyle pa-0 pb-6 color-999 tip" v-text="info.itemTip"></v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        </div>
      </div>
    </v-container>
</div>

  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
import qs from 'qs'
let _this;
export default {
  name: 'customersCase',
  components: {
  },
  data() {
    return {
        imgLoad: false,
        data: contentData.developCase,
        tab: null,
        showDialog: false
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
    },
    goCaseDetail(id) {
      let query = {
        id: id,
      }
      let url = '/develop/ExperienceDetail?'+qs.stringify(query)
      window.open(url, '_blank')
      console.log(id)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-window__container{
  min-height: 380px;
}

::v-deep v-tabs-slider-wrapper{
  width: 32px;
}
::v-deep ::-webkit-scrollbar{display:none; width:0; height: 0;}
.section-box{
  background-color: #F7F8F9 !important;
  // margin-left: calc(100vw - 100%);
  min-height:100vh
}
.g-tabs-two .v-tab{
  padding: 0px;
}
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .theme--light.v-tabs-items{
     background: #f5f8fd !important; 
}
::v-deep .new-tabs-two{
  height: 78px !important;
  padding: 0 !important;
}
::v-deep .new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-box{
  margin-top: -40px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  .main-item{
    // position: absolute;
    // top: -40px;
    width: 100%;
    .v-card__subtitle, .v-card__title{
      color: #666666 !important;
    }

    .item-card{
      padding: 10px;
      box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
      border-radius: 8px;
      transition: 0.3s ease;
      &:hover {
        transform: scale(1.04);
        box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
        // transform:translateY(-16px);
      }
      .text-box {
        .title {
          font-size: 14px !important;
          font-weight: 400;
          color: #333333;
          padding: 0 !important;
          margin-left: 3px;
          margin: 16px 0 8px 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 180px;
          text-align: start;
          height: unset;
        }
        &::after {
          content: "";
          background-image: url("../../../assets/images/home/case-icon.png");
          background-size: 100%;
          width: 20px;
          height: 20px;
          position: absolute;
          bottom: 45px;
          right: 10px;
        }
        &:focus {
          display: none !important;
        }
     
      }
    }
  }
}
</style>
